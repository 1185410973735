<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">名称：</span>
					<el-input v-model="searchinput" placeholder="请输入名称" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
					<el-button type="success" @click="addnew">新增类型</el-button>
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="150" @getdata="gettable">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="editerow(scope.row)" type="warning" size="mini">编辑</el-button>
							<el-button @click="deleterow(scope.row)" type="danger" size="mini">删除</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>
		<!-- 新增类型弹窗 -->
		<el-dialog :title="isEdit?'编辑类型':'新增类型'" :visible.sync="dialogVisible" :before-close="resetdata" width="600px">
			<el-form :model="form" :rules="rules" ref="form" label-width="80px">
				<el-form-item label="类型名称" prop="name">
					<div style="display: flex;align-items: center;">
						<el-input v-model="form.name"></el-input>
					</div>
				</el-form-item>
				<el-form-item label="排序">
					<div style="display: flex;align-items: center;">
						<el-input v-model="form.weigh" type="number" placeholder=""></el-input>
					</div>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="resetdata">取消</el-button>
				<el-button type="primary" @click="submitForm('form')">确定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				searchinput: '',
				tableHeader: [{
						prop: 'id',
						name: '序',
						width: '50'
					},
					{
						prop: 'name',
						name: '类型名称',
						width: ''
					},
					{
						prop: 'weigh',
						name: '排序',
						width: ''
					}
				],
				tableData: [],
				dialogVisible: false,
				form: {
					id: null,
					name: '',
					weigh:0
				},
				isEdit: false,
				rules: {
					name: [{
						required: true,
						message: '请输入类型名称',
						trigger: 'blur'
					}]
				}
			}
		},
		mounted() {
			this.gettable()
		},
		methods: {
			resetdata(){
				this.form = {
					id: null,
					name: '',
					weigh:0
				}
				this.dialogVisible = false
				this.isEdit = false
			},
			editerow(row) {
				this.isEdit = true;
				this.form = {
					...row
				}
				this.dialogVisible = true;
			},
			deleterow(row) {
				this.$confirm('请确认是否删除该类型?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post({
						url: '/api/questionnairetktype/del',
						params: {
							ids:row.id
						}
					}).then((res) => {
						this.$message.success('删除成功');
						this.gettable()
					})
				})
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/questionnairetktype/index',
					params: {
						name: this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize
					}
				}).then((res) => {
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			addnew() {
				this.dialogVisible = true;
			},
			showdate(row) {
				this.$refs.myroomdate.dialogVisible = true
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {

						if (this.isEdit) {
							this.$post({
								url: '/api/questionnairetktype/edit',
								params: this.form
							}).then((res) => {
								this.$message.success('编辑成功');
								this.gettable()
								this.resetdata()
							})
						} else {
							this.$post({
								url: '/api/questionnairetktype/add',
								params: this.form
							}).then((res) => {
								this.$message.success('添加成功');
								this.gettable()
								this.resetdata()
							})
						}

					} else {
						this.$message.error('请检查表单');
						return false;
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import 'list.scss';

	.option-item {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
	}

	.option-item .el-input {
		flex: 1;
		margin-right: 10px;
	}
</style>
